import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

function ShowUser(){
    const {id} = useParams();
    const [user,setUser] = useState(null);
    const [loading,setLoading] = useState(true);


    useEffect(()=> {
        fetch("https://jsonplaceholder.typicode.com/users/" + id)
            .then(response => response.json())
            .then(response => {
                setUser(response)
                setLoading(false)
            })
            .catch(error => {
                console.log("error when get user list",error)
                setLoading(false)
                setUser([]);
            })
        
    },[id])

    return (
        <>
            <div className="container mt-5">
                <div className="row g-3">
                    {loading && <div className="spinner-border"></div>}
                    {user && <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">
                                {user.name}
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">username : {user.username}</li>
                                <li className="list-group-item">email : {user.email}</li>
                                <li className="list-group-item">phone : {user.phone}</li>
                                <li className="list-group-item">website : {user.website}</li>
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}
export  default  ShowUser;