import {useEffect, useState} from "react";
import Swal from "sweetalert2";

const EditForm = ({ post }) => {
    const  [title,setTitle] = useState('');
    const  [body,setBody] = useState('');
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    function  handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        fetch('https://jsonplaceholder.typicode.com/posts/' + post.id, {
            method: 'PUT',
            body: JSON.stringify({
                title: title,
                body: body,
                userId: 1,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json)
                Swal.fire({
                    title: "woooow!!!",
                    text: "Post Edied!",
                    icon: "success"
                });

                setLoading(false)
            })
            .catch((error)=> {
                setError(error.message);
                setLoading(false);
            })

    }
    useEffect(()=>{
        setTitle(post.title)
        setBody(post.body)
    },[post])

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Body</label>
                    <textarea value={body} onChange={(e) => setBody(e.target.value)} className="form-control" rows="3"></textarea>

                </div>
                <button className="btn btn-dark" type="submit" disabled={title === '' || body === '' || loading}>
                    {loading && <div className="spinner-border spinner-border-sm me-2"></div>}
                    Update Post
                </button>
                {error && <div className="mt-2 fw-bold text-danger">{error}</div>}
            </form>
        </>
    )
}

export default EditForm;