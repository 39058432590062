// App.js
import React from 'react';
import RoutesProvider from './routes';

function App() {
    return (
        <>
            <RoutesProvider />
        </>
    );
}

export default App;