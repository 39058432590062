// routes.js
import React from 'react';
import {createBrowserRouter, Outlet, RouterProvider} from 'react-router-dom';
import Home from './pages/Home';
import Header from "./components/Header";
import ShowUser from "./pages/users/Show";
import IndexUser from "./pages/users/Index";
import IndexPost from "./pages/posts/Index";
import ShowPost from "./pages/posts/Show";
import CreatePost from "./pages/posts/Create";
import EditPost from "./pages/posts/Edit";


const AppLayout = () => {
    return (
        <>
            <Header/>
            <Outlet/>
        </>
    );
}

export const routes = {
    home: '/',
    indexUser: '/users',
    showUser: '/users/:id',
    indexPost: '/posts',
    showPost: '/posts/:id',
    createPost: '/posts/create',
    editPost: '/posts/edit/:id',
};

const router = createBrowserRouter([
    {
        element: <AppLayout/>,
        children: [
            {
                path: routes.home,
                element: <Home/>,
            },
            {
                path: routes.indexUser,
                element: <IndexUser/>,
            },
            {
                path: routes.showUser,
                element: <ShowUser/>,
            },
            {
                path: routes.indexPost,
                element: <IndexPost/>,
            },
            {
                path: routes.showPost,
                element: <ShowPost/>,
            },
            {
                path: routes.createPost,
                element: <CreatePost/>,
            },
            {
                path: routes.editPost,
                element: <EditPost/>,
            },
        ]
    }]);


const RoutesProvider = () => {
    return <RouterProvider router={router}/>;
};

export default RoutesProvider;