import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import EditForm from "../../components/posts/Edit";

function EditPost() {


    const {id} = useParams();


    const [loading,setLoading] = useState(true);
    const [post, setPost] = useState(null);
    useEffect(() => {
        fetch(`https://jsonplaceholder.typicode.com/posts/${id}`)
            .then(res => res.json())
            .then(data => {
                setPost(data);
                setLoading(false)
            }).catch(err => {
            setLoading(false)
        })

    }, []);


    return (
     <>
         <div className="container mt-5">
             <div className="row g-3">
         <div className="col-md-6">
             <h2>Edit Post :</h2>
             {loading && <div className="spinner-border"></div>}
             {post && <EditForm post={post} />}
         </div>
             </div>
         </div>
     </>
    )
}
export  default  EditPost;
