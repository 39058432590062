import {useEffect, useState} from "react";
import UserList from "../../components/users/List";

function IndexUser(){
    const [userList,setUserList] = useState(null);
    const [loading,setLoading] = useState(true);


    useEffect(()=> {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then(response => {
                setUserList(response)
                setLoading(false)
            })
            .catch(error => {
                console.log("error when get user list",error)
                setLoading(false)
                setUserList([]);
            })

    },[])

    return (
        <>
            <div className="container mt-5">
                <div className="row g-3">
                    <h2>Users :</h2>
                    {loading && <div className="spinner-border"></div>}
                    {userList && <UserList userList={userList} />}
                </div>
            </div>
        </>
    )
}
export  default  IndexUser;